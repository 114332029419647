.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  /*color: #f0f0f0;*/
  margin: 0;
}

.container a {
  text-decoration: none;
}

.detail {
  padding-bottom: 20px;
}

.about-text {
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  /*color: #ffffff !important;*/
}

.about-text-small {
  text-align: left;
  font-size: 12px !important;
  padding-left: 15px;
  padding-right: 15px;
  /*color: #ffffff !important;*/
}


@media screen and (max-width: 480px) {
  .container.about-container {
    margin-top: 80%;
  }
}

@media screen and (max-width: 640px) and (min-width: 481px) {
  .container.about-container {
    top: 55%;
  }
}

@media screen and (min-width: 641px) {
  .container.about-container {
    top: 52%;
  }
}

@media screen and (max-height: 100px) {
  .container.about-container {
    top: 100%;
  }
}

@media screen and (max-width: 480px) {
  .container.experience-container {
    margin-top: 50%;
  }
}

@media screen and (max-width: 640px) and (min-width: 481px) {
  .container.experience-container {
    top: 55%;
  }
}

@media screen and (min-width: 641px) {
  .container.experience-container {
    top: 52%;
  }
}

@media screen and (max-height: 100px) {
  .container.experience-container {
    top: 100%;
  }
}

.about-text-subtitle {
  font-size: 16px !important;
  font-weight: bold;
}

.item {
  font-size: 20px !important;
  font-weight: bold;
}

.subitem {
  padding-left: 50px !important;
  font-size: 18px !important;
}

.subsubitem {
  padding-left: 100px !important;
  font-size: 16px !important;
}

.subsubsubitem {
  padding-left: 150px !important;
  font-size: 16px !important;
}

.tab { margin-left: 40px !important; }
.tabtab { margin-left: 60px !important; }